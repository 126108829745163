import './App.css';
import CRoutes from './components/CRoutes';

function App() {
  return (
    <CRoutes />
  );
}

export default App;
